import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/main.scss';

import Nav from '../components/Nav';
import Footer from '../components/Footer';

const Contact = () => {
	return (
		<>
      <Nav />
        <div className="container">
          <div className="row pt100 pb100">
            <div className="col-6">
              <h1>Contact</h1>
              <div>foo</div>
              <div>foo</div>
              <div>foo</div>
            </div>
            <div className="col-6">
              <h1>Contact</h1>
              <div>foo</div>
              <div>foo</div>
              <div>foo</div>
            </div>
          </div>
        </div>
      <Footer />
    </>
  );
};

export default Contact;