import React, { useState, useEffect, useRef } from "react";
import { createClient } from "microcms-js-sdk";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/main.scss';
import backgroundMitsu from '../assets/images/mitsu.jpg';
import backgroundSalon from '../assets/images/salon.jpg';

import Nav from '../components/Nav';
import Footer from '../components/Footer';

const client = createClient({
  serviceDomain: "ztn5pjtobd",
  apiKey: "YBNJ1MrW7XXop6nn4RHB1dBbFSQe23EVwYxU",
});

const Top = () => {
  const [message, setMessage] = useState("");
  const images = [
    "../assets/images/hero1.jpg",
    "../assets/images/hero2.jpg",
    "../assets/images/hero3.jpg"
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const twitterRef = useRef(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000);
    return () => clearInterval(timer);
  }, [images.length]);

  useEffect(() => {
    client
      .get({
        endpoint: 'news',
      })
      .then((res) => {
        setMessage(res.contents[0].title);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.charset = "utf-8";
    twitterRef.current.appendChild(script);

    // Twitterウィジェットのロード
    if (window.twttr) {
      window.twttr.widgets.load();
    }
  }, []);

  return (
    <>
      <Nav />
      <div className="main-vidual">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide inset-0 transition-opacity duration-1000 ${
              index === currentImageIndex ? "opacity-100" : "opacity-0"
            }`}
          >
            <img src={image} alt="紫護縄びんご" />
          </div>
        ))}

        <div className="main-visual-overlay">
          <div className="main-visual-bigcopy">
            {/* <h2 className="main-visual-title">密蜜 -mitsu-</h2> */}
            <p className="main-visual-subtitle mt100">歌舞伎町 和風SMバー</p>
            <div class="flex-container">
                <img src="/assets/images/shop_logo1.png" class="mv-logo" alt="" />
            </div>
            
          </div>
        </div>
      </div>

        <div className="container index-area">
            <div className="row">
              <div className="col-lg-2 col-md-3 mb-3 d-none d-md-block right-side">
                <ul>
                    <li><a href="/">店舗情報</a></li>
                    <li><a href="/">スタッフ</a></li>
                    <li><a href="/">スケジュール</a></li>
                    <li><a href="/">ブログ</a></li>
                    <li><a href="/">ギャラリー</a></li>
                    <li><a href="/">リクルート</a></li>
                    <li><a href="/">リンク</a></li>
                </ul>
              </div>
              <div className="col-lg-5 col-md-9 mb-3 content-column-b">
                <h3>01.15 <span className="wday" style={{ fontSize: '14px' }}>tue</span></h3>
                  <div>密蜜-mitsu-の思い</div>
                  <div className="text">
                      「自分たちが素晴らしいと思える、 この世界を知らない方達にも 知ってもらいたい」 という思いではじめました。<br></br>
                    その言葉には沢山の 要素を含んでいます<br></br>
                    ここでは誰もあなたを否定しません。<br></br>
                    あなたの素晴らしい世界を 密蜜-mitsu-で見つけてください。
                  </div>

                <h3 className="mt20">09.16 <span className="wday" style={{ fontSize: '14px' }}>tue</span></h3>
                  <div>
                    昼間なら遊びに行けるのに・・の声にお応えして、「日曜密蜜」始まってます！<br></br>
                    日曜日の昼下がりもいつもの密蜜-mitsu-でお会いしましょう。<br></br>
                    営業時間 13:00～19:00<br></br>

                    19:00～24:00は、ふぅの密蜜弁財天<br></br>
                    <a href="http://bar-mitsu.com" target="_blank" rel="noopener noreferrer">http://bar-mitsu.com</a>
                    <br></br>
                    ※通常営業とシステムが変わります</div>

                  <h3 className="mt20">12.22 <span className="wday" style={{ fontSize: '14px' }}>tue</span></h3>
                  <div>
                    2013年12月25日、クリスマスの夜に<br></br>
                    「密蜜-mitsu-」オープンです!<br></br>
                    多彩なゲストをお迎えしています。<br></br>
                    どうぞおいでください。<br></br>
                    詳しくはこちら
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 mb-3 content-column-c">
                <h3>10.15<span className="wday" style={{ fontSize: '14px' }}>tue</span></h3>
                    昼営業: 13時〜18時<br></br>
                    夜営業: 18時〜24時<br></br>
                    チャージ: 男性7,000円 / 女性3,000円 フリードリンク<br></br>
                    10月<br></br>
                    <br></br>
                    14日（月）昼: - / 夜: ふぅ<br></br>
                    15日（火）昼: masa / 夜: ふぅ<br></br>
                    16日（水）昼: びんご/ 夜: -<br></br>
                    17日（木）昼: ひいな / 夜: masa<br></br>
                    18日（金）昼: - / 夜: ふぅ<br></br>
                    19日（土）昼: - / 夜: びんご<br></br>
                    20日（日）昼: - / 夜: ふぅ<br></br>
                    18時〜24時 <a href="https://biwa.tokyo/mitsu">https://biwa.tokyo/mitsu</a><br></br>
                    <br></br>
                    21日（月）昼: - / 夜: ふぅ<br></br>
                    22日（火）昼: masa / 夜: ふぅ<br></br>
                    23日（水）昼: びんご/ 夜: -<br></br>
                    24日（木）昼: ひいな / 夜: masa<br></br>
                    25日（金）昼: - / 夜: ふぅ<br></br>
                    26日（土）昼: LAZYcafe / 夜: びんご<br></br>
                    27日（日）昼: kaz / 夜: ふぅ<br></br>
                    18時〜24時 <a href="https://biwa.tokyo/mitsu" target="_blank" rel="noopener noreferrer">Visit Biwa Tokyo</a>
                    <br></br>
                    <br></br>
                    28日（月）昼: - / 夜: ふぅ<br></br>
                    29日（火）昼: masa / 夜: ふぅ<br></br>
                    30日（水）昼: アラタ/ 夜: -<br></br>
                    31日（木）昼: ひいな / 夜: びんご<br></br>
                    1日（金）昼: - / 夜: ふぅ<br></br>
                    2日（土）昼: - / 夜: ふぅ<br></br>
                    3日（日）昼: - / 夜: ふぅ<br></br>
                    18時〜24時 <a href="https://biwa.tokyo/mitsu" target="_blank" rel="noopener noreferrer">https://biwa.tokyo/mitsu</a>
                    <br></br>
                </div>
            </div>
        </div>




      <div className="container second-area">
        <div className="row">
          <div className="col-md-4">
            <a href="/mitsu">
              <div
                style={{ backgroundImage: `url(${backgroundMitsu})` }}
                className="shigog shigog1 mitsu"
              >
                {/* <p className="shigog-pre-title">歌舞伎町和風BAR</p> */}
                <h3 className="shigog-title">密蜜</h3>
                <p className="shigog-summary mt20">新宿歌舞伎町に佇む<br></br>
                はじめての和風SMバー</p>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <div
              style={{ backgroundImage: `url(${backgroundSalon})` }}
              className="shigog shigog2 salon"
            >
              {/* <p className="shigog-pre-title">体験型SMサロン</p> */}
              <h3 className="shigog-title">紫護縄サロン</h3>
              <p className="mt20">
              解放と赦しを求めて<br></br>
              ここに「体験」の極がある
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="shigog shigog2 backdoor">
              {/* <p>歌舞伎町和風BAR</p> */}
              <h3>BACK DOOR</h3>
              <p>欲望への入口</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container third-area">
        <div className="row">
          <div className="col-md-6">
            <h4 className="info-title">News</h4>
            <ul className="information">
              <li>{message}</li>
              <li>2024-09-30 [紫護縄サロン] スケジュール変更...</li>
              <li>2024-09-30 [密蜜] 紫護縄びんごをよろしく...</li>
              <li>2024-09-30 紫護縄びんご new site オープン...</li>
            </ul>
          </div>
          <div className="col-md-6">
            <h4 className="info-title">お知らせ</h4>
            <div ref={twitterRef} style={{ padding: `30px` }}>
              <a
                className="twitter-timeline"
                data-width="500"
                data-height="500"
                data-dnt="true"
                data-theme="dark"
                href="https://twitter.com/shigonawa?ref_src=twsrc%5Etfw"
              >
                Tweets by shigonawa
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Top;
