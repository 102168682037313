import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import routes from './routes';

import Top from './pages/Top';
import Contact from './pages/Contact';
import About from './pages/About';
import News from './pages/News';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import List from './pages/List';


/**
 * top page
 * contact
 * about
 * @returns 
 */
function App() {
  return (
    <Router>
      <Routes>
        <Route path={routes.top} element={<Top />} />
        <Route path={routes.contact} element={<Contact />} />
        <Route path={routes.about} element={<About />} />
        <Route path={routes.news} element={<News />} />
        <Route path={routes.privacy_policy} element={<PrivacyPolicy />} />
        <Route path={routes.top} element={<Top />} />
        <Route path={routes.terms_of_use} element={<TermsOfUse />} />
        <Route path={routes.list} element={<List />} />
      </Routes>
    </Router>
  );
}
export default App;
