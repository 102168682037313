const routes = {
    top: "/",
    contact: "/contact",
    about: "/about",
    news: "/news",
    privacy_policy: "/privacy_policy",
    terms_of_use: "/terms_of_use",
    list: "/list",
  };
  
export default routes;