import React, { useState } from 'react';
import '../scss/main.scss';
import routes from '../routes';

const Nav = () => {
  // サイドバーの開閉状態を管理するステート
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // サイドバーのトグル関数
  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState); // 状態を前の状態から反転
  };

  return (
    <>
      <div className="topbar"></div>
      <div className="navi">
        <div className="navi-menu">
          <a href="/">
            <img className="navi-logo" src="/assets/images/logo.png" alt="蜜密" />
            <span className="navi-title">蜜密 - mitsu -</span>
          </a>
          <ul className="navi-list">
            <li className="navi-item"><a href={routes.top}>Home</a></li>
            <li className="navi-item"><a href={routes.about}>About</a></li>
            <li className="navi-item"><a href={routes.contact}>Contact</a></li>
          </ul>
        </div>
        <div className="navi-subindex" onClick={toggleSidebar}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
        </svg>
        </div>
      </div>

      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button
          className="close-btn"
          onClick={toggleSidebar}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
        </button>
        <div className="sidebar-content">
          <h5 className="sidebar-title">紫護縄びんご</h5>
          <ul>
              <li><a href="/">店舗情報</a></li>
              <li><a href="/">スタッフ</a></li>
              <li><a href="/">スケジュール</a></li>
              <li><a href="/">ブログ</a></li>
              <li><a href="/">ギャラリー</a></li>
              <li><a href="/">リクルート</a></li>
              <li><a href="/">リンク</a></li>
          </ul>
        </div>
        <p>ここにサイドバーの内容が入ります。</p>
        <p>適当な文字列を追加してください。</p>
      </div>
    </>
  );
};

export default Nav;
