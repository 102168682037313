import routes from '../routes';
const Footer = () => {
  return (
    <>
      <div className="footer container-fluid second-area footer-pc">
        <div className="footerbar"></div>
        <div className="footer-section">
          <div className="footer-section-item">
            <ul>
              <li><a href={routes.top}>Home</a></li>
              <li><a href={routes.about}>About</a></li>
              <li><a href={routes.contact}>Contact</a></li>
              <li><a href={routes.terms_of_use}>ご利用規約</a></li>
              <li><a href={routes.privacy_policy}>プライバリーポリシー</a></li>
            </ul>
            <p className="copyright">© shigonawabingo.com</p>
          </div>
        </div>
      </div>

      <div className="footer container-fluid second-area footer-sp">
        <ul>
          <li><a href={routes.top}>Home</a></li>
          <li><a href={routes.about}>About</a></li>
          <li><a href={routes.contact}>Contact</a></li>
          <li><a href={routes.terms_of_use}>ご利用規約</a></li>
          <li><a href={routes.privacy_policy}>プライバリーポリシー</a></li>
        </ul>
      </div>
    </>
  );
};

export default Footer;
