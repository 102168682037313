import routes from '../routes';
import '../scss/main.scss';

const List = () => {
  const env = process.env.REACT_APP_ENV;
  const url = process.env.REACT_APP_URL;
	return (
		<>
    <div className="list">
      <h1>List</h1>
        <ul>
          {Object.entries(routes).map(([key, path]) => (
            <li key={key}>
            {key}: <a href={path}>{key}</a>
            </li>
          ))}
        </ul>
        <hr />
        <h1>meta</h1>
          <ul>
            <li>REACT_APP_ENV: {env}</li>
            <li>REACT_APP_URL: {url}</li>
          </ul>
          <div>
          # shigonawabingo.com
          Host bingo
          HostName ssh.lolipop.jp
          User capoo.jp-shigonawabingo
          Port 2222
          IdentityFile ~/.ssh/sshkey/yamakami/shigonawabingo.com/bingo.pem
          </div>
        </div>
      </>
    );
};

export default List;